import React from "react"
import PropTypes from "prop-types"
import BuilderPanel from "./BuilderPanel"
import FormPreview from "./FormPreview"

import FieldList from "./FieldList"
import FieldSettings from "./FieldSettings"

class FormBuilder extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      browserSupported: true,
      projectUrl: props.project_url,
      currentUserId: props.current_user_id,
      formTitle: 'Form Builder',
      currentlyBuilding: false,
      fields: [],
      sdkUrl: props.sdk_url,
      subdomainToken: props.subdomain_token,
      formBuilderCreateUrl: props.form_builder_create_url,
      inviteUsersUrl:props.inviteUsersUrl,
      middleColumnClasses: "col-md-8 border-right",
      formIsBeingPublished: false,
      formHasBeenPublished: false,
      failedToPublish: false,
      surveyRecord: null,
      nextFieldIndex: 0,
      editingFieldIndex: -1,
      optionLists: {},
      editingOption: {},
      fieldEditMode: false,
      optionEditMode: false,
      currentOptionList: '',
      gettingLocation: false,
      validationAlertIndex: 0,
      validationAlertMessages: [
        "Field Name, Field Label, and Field Type are all required to add a field.",
        "Fields with a select type require an option list. Please select a list or start a new one.",
        "Field names can not contain uppercase letters, or spaces.\nThe only special characters allowed are underscores ( _ ).\nPlease enter a new name for this field following those rules.",
        "Field names can only be used for one field per form. Please enter a unique 'name' for this field."
      ],
      validatingField:{
        name: '',
        label: '',
        type: '',
        required: false
      },
      currentField: {
        name: '',
        label: '',
        type: '',
        required: false
      },
      currentOption: {
        name: '',
        label: ''
      },
      editField:{
        name: '',
        label: '',
        type: '',
        required: false
      }
    };
    this.startBuilding = this.startBuilding.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.addField = this.addField.bind(this);
    this.controlField = this.controlField.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.addOption = this.addOption.bind(this);
    this.controlOption = this.controlOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.publishForm = this.publishForm.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }

  componentWillMount(){
    if(this.detectBrowserSupport()){
      this.setState({browserSupported: true});
    }
    else{
      this.setState({browserSupported: false});
    }
  }

  detectBrowserSupport() {
    /**
     * detect IE
     * returns false if browser is Internet Explorer
     */
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older
        return false
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11
        var rv = ua.indexOf('rv:');
        return false
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+)
       return false
    }

    // other browser
    return true;
  }

  startBuilding(){
    this.setState({
      currentlyBuilding: true,
      middleColumnClasses: "col-md-4 border-right"
    })
  }

  updateTitle(value){
    if (value == ''){
      this.setState({
        formTitle: 'Form Builder'
      })
    }else{
      this.setState({
        formTitle: value
      })
    }
  }

  addField(field){
    let validationCallback = (valid) => {
      if (valid){
        let field = this.state.validatingField;
        let fields = this.state.fields;
        let sortedFields;
        let fieldIndex = this.state.nextFieldIndex;
        if (this.state.fieldEditMode){
          fields.splice(this.state.editingFieldIndex, 1);
        }else{
          field["index"] = fieldIndex;
        }
        field["optionList"] = this.state.currentOptionList;
        fields.push(field);
        sortedFields = fields.sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
        this.setState({
          fields: sortedFields,
          currentField: {
            name: '',
            label: '',
            type: '',
            required: false
          },
          validatingField:{
            name: '',
            label: '',
            type: '',
            required: false
          },
          validationAlertIndex: 0,
          editingFieldIndex: -1,
          nextFieldIndex: (fieldIndex += 1),
          fieldEditMode: false,
          currentOptionList: ''
        })
      }
      else{
        alert(this.state.validationAlertMessages[this.state.validationAlertIndex] )
      }
    }
    this.setState({validatingField: field}, () => {
      this.validateField(validationCallback)
    })
  }

  validateField(validationCallback){
    let valid = false;
    let isFormattedCorrectly = false;
    let isUnique = false;
    let isCompleteCallback = (isComplete) => {
      if(isComplete){
        valid = true;
        isFormattedCorrectly = this.isFormattedCorrectly();
        if(isFormattedCorrectly){
          valid = true;
          isUnique = this.isUnique();
          if(isUnique){
            valid = true;
          }
          else{
            valid = false;
          }
        }
        else{
          valid = false;
        }
      }
      else{
        validationCallback(false);
        return false
      }
      if(valid){
        validationCallback(true);
      }
      else{
        if(!isFormattedCorrectly){
          let formattedName = this.formatFieldName();
          if (confirm(`In order for this name to become a valid data point, it will be reformatted to "${formattedName}".\nClick OK to use this name, or Cancel to enter a new one.`)) {
            let validatingField = this.state.validatingField;
            validatingField["name"] = formattedName;
            this.setState({validatingField: validatingField});
            isUnique = this.isUnique();
            if(!isUnique){
              this.setState({validationAlertIndex: 3}, () => {
                validationCallback(false);
                return null
              });
            }
            else{
              validationCallback(true);
            }
          } else {
            this.setState({validationAlertIndex: 2}, () => {
              validationCallback(false);
              return null
            });
          }
        }
        else if(!isUnique){
          this.setState({validationAlertIndex: 3}, () => {
            validationCallback(false);
            return null
          });
        }
      }
    }
    this.isComplete(isCompleteCallback);
  }

  isComplete(callback){
    if(this.state.validatingField.name === ''){
      this.setState({validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if (this.state.validatingField.label === ''){
      this.setState({validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if (this.state.validatingField.type === ''){
      this.setState({validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if(this.state.validatingField.type.includes('select')){
      if(this.state.currentOptionList === '' || this.state.currentOptionList === "empty-option"){
        this.setState({validationAlertIndex: 1}, () => {
          callback(false)
        });
      }
      else{
        callback(true)
      }
    }
    else{
      callback(true)
    }
  }

  isFormattedCorrectly(){
    // only allow names that are lowercase, snakecased, and alphanumeric, to be MySQL column names
    let permittedFormat = /^[a-z0-9_"]*$/;
    if(this.state.validatingField.name.match(permittedFormat)){
      // can't start with a number
      if(this.state.validatingField.name.match(/(^[0-9])/)){
        return false
      }
      return true
    }
    else{
      return false
    }
  }

  isUnique(){
    // column names for the database table must be unique to the dataset
    if(this.state.fieldEditMode){
      if(this.state.validatingField["name"] === this.state.editFieldName){
        return true
      }
      else{
        let unique = true;
        let fields = this.state.fields
        for (var i = 0, len = fields.length; i < len; i++) {
          if(fields[i]["name"] === this.state.validatingField["name"]){
            if(i !== this.state.editingFieldIndex){
              unique = false;
              break;
            }
          }
        }
        return unique
      }
    }
    else{
      let unique = true;
      let fields = this.state.fields
      for (var i = 0, len = fields.length; i < len; i++) {
        if(fields[i]["name"] === this.state.validatingField["name"]){
          unique = false;
          break;
        }
      }
      return unique
    }
  }

  formatFieldName(){
    // remove whitespaces, and convert to snakecase (lowercase with underscore)
    let name = this.state.validatingField.name.trim()
    .replace(/(^[A-Z])/, ([leadingUppercase]) => leadingUppercase.toLowerCase())
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/([A-Z])/g, ([innerUppercase]) => `${innerUppercase.toLowerCase()}`)
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    .replace(/(^[0-9])/, ([leadingNumber]) => `_${leadingNumber}`)
    return name
  }

  controlField(field, control){
    switch(control){
      case "edit-field":
        let index = this.state.fields.indexOf(field);
        this.setState({
          currentField: field,
          editingFieldIndex: index,
          editFieldName: field["name"],
          fieldEditMode: true,
          currentOptionList: field["optionList"]
        })
      break;
      case "delete-field":
        this.deleteField(field);
      break;
    }
  }


  deleteField(field){
    let fields = this.state.fields;
    let nextFieldIndex = this.state.nextFieldIndex;
    fields.splice(field["index"], 1);
    for (var i = 0, len = fields.length; i < len; i++) {
      fields[i]["index"] = i;
    }
    this.setState({
      nextFieldIndex: nextFieldIndex -= 1,
      fields: fields,
      fieldEditMode: false,
      currentField: {
        name: '',
        label: '',
        type: '',
        required: false
      }
    })
  }

  selectOptionsList(listName){
    switch(listName){
      case 'empty-option':
        this.setState({
          currentOptionList: ''
         })
      break;
      case 'new-option-list':
        this.setState({
          currentOptionList: listName
         })
      break;
      default:
        this.setState({
          currentOptionList: listName
         })
    }
  }

  startOptionList(listName){
    let optionLists = this.state.optionLists;
    // format to fit XLSForm standard for choice list_names
    let formattedName = listName.trim()
    .replace(/(^[A-Z])/, ([leadingUppercase]) => leadingUppercase.toLowerCase())
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/([A-Z])/g, ([innerUppercase]) => `${innerUppercase.toLowerCase()}`)
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    optionLists[formattedName] = []
    this.setState({
      optionLists: optionLists,
      currentOptionList: formattedName
    })
  }

  addOption(listName, optionName, optionLabel){
    let optionLists = this.state.optionLists;
    if(this.state.optionEditMode){
      this.setState({
        editingOption: {},
        optionEditMode: false,
        currentOption: {
          name: '',
          label: ''
        }
      })
    }
    else{
      optionLists[listName].push({
        name: optionName,
        label: optionLabel
      })
      this.setState({
        optionLists: optionLists,
        currentOption: {
          name: '',
          label: ''
        }
      })
    }
  }

  controlOption(option, control){
    switch(control){
      case "option-edit":
        this.setState({
          currentOption: option,
          optionEditMode: true
        })
      break;
      case "option-delete":
        this.deleteOption(option);
      break;
    }
  }

  deleteOption(option){
    let optionLists = this.state.optionLists;
    let options = optionLists[this.state.currentOptionList];
    let index;
    for(let i = 0; i < options.length; i++){
      if(JSON.stringify(options[i]) === JSON.stringify(option)){
        index = i;
        break;
      }
    }
    options.splice(index, 1);
    optionLists[this.state.currentOptionList] = options;
    this.setState({
      optionLists: optionLists
    })
  }

  publishForm(){
    if (window.confirm('Are you sure the form is ready to build? Please note that the form you just built is a preview, and that any data entered will not be collected. Once the form has been built, it can not be changed.')){
      this.setState( { formIsBeingPublished: true } );
      let that = this;
      fetch(this.state.formBuilderCreateUrl,{
        method: 'POST',
        headers: {
                'Accept': 'application/json',
                'Content-Type': ' application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': this.props.csrf_token
              },
        body: JSON.stringify({user_id: this.state.currentUserId, project_id: this.props.project_id, form_title: this.state.formTitle, form_fields_attributes: this.state.fields, options_for_selects: this.state.optionLists})
      }).then(response => {
        response.json().then(res =>{
          let responseBody = JSON.parse(res.body);
          if(responseBody.saved){
            that.setState({
              formIsBeingPublished: false,
              surveyRecord: responseBody.survey
            })
            if (responseBody.project_survey_count > 1){
              // responseBody.project_survey_count > 1
              // show success modal if this is NOT a part of initial project set up
              that.setState({ formHasBeenPublished: true })
            }
            else{
              // redirect user to invite page if this is a part initial project set up
              window.location.replace(this.state.inviteUsersUrl);
            }
          }else{
            that.setState({
              formIsBeingPublished: false,
              failedToPublish: true
            })
          }
        })
      })
    }
  }

  buildAnother(){
    document.getElementById("builder-title").value = "";
    this.setState({
      formTitle: 'Form Builder',
      currentlyBuilding: false,
      fields: [],
      formIsBeingPublished: false,
      formHasBeenPublished: false,
      failedToPublish: false,
      surveyRecord: null,
      nextFieldIndex: 0,
      editingFieldIndex: -1,
      optionLists: {},
      editingOption: {},
      fieldEditMode: false,
      optionEditMode: false,
      currentOptionList: '',
      gettingLocation: false,
      currentField: {
        name: '',
        label: '',
        type: '',
        required: false
      },
      currentOption: {
        name: '',
        label: ''
      }

    });
  }

  getLocation(event){
    this.setState({
      gettingLocation: true
    })
    let output = event.target.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = 'Latitude: ' + position.coords.latitude + ' Longitude: ' + position.coords.longitude
      this.setState({
        gettingLocation: false
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  render() {
    // setup for the response modals
    let publishingStatus;
    if(this.state.failedToPublish){
      // if there is an error on the server when creating the datset, show this modal
      publishingStatus =
      <div className="mask active" role="dialog">
        <div className="active builder-modal bg-light p-4 shadow-lg rounded" role="alert">
          <div className="active builder-modal col-sm-10 bg-light shadow-lg rounded p-4" role="alert">
          <button type="button" className="close" aria-label="Close" onClick={(event) => this.buildAnother(event)}><span>&times;</span></button>
            <div className="modal-dialog mt-5">
              <div>
                <p>Something terrible has happened! But we have recorded the error in our logs, and we will investigate the problem. Try <span onClick={(event) => this.buildAnother(event)} className="build-another">building another form</span>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    else if(this.state.formHasBeenPublished) {
        // process was successful, show this modal
      publishingStatus =
      <div className="mask active" role="dialog">
        <div className="active builder-modal success col-sm-10  bg-light shadow-lg rounded p-4" role="alert">
        <button type="button" className="close" aria-label="Close" onClick={(event) => this.buildAnother(event)}><span>&times;</span></button>
        <div className="modal-dialog">
          <div>
          <h2><span className="big">Nicely done! 🎉</span><br></br>Your form is built and launched!</h2>
          <div className="row">
            <div className="col-sm-6">
            <div className="bottom-space">
            <h4>Web</h4>
            <p>You can review the public web form now or view the data manager page once you start collecting surveys <a href={this.state.sdkUrl + "/surveys/" + this.state.surveyRecord["slug"] + "/records?st=" + this.state.subdomainToken}>here.</a> </p>
            <a href={this.state.sdkUrl + "/surveys/" + this.state.surveyRecord["slug"] + "/form?ft=" + this.state.surveyRecord["web_form_token"] + "st=" + this.state.subdomainToken} target="_blank" className="btn btn-primary">Public Web Form</a>
            </div>
            <h4>Android & iOS</h4>
            <p>To help you collect more data, make sure to get the mobile app, <strong>SDK 2</strong>, from the <a href="https://play.google.com/store/apps/details?id=com.sdkmobileapp">Google Play Store</a> <strong>and</strong> the <a href="https://itunes.apple.com/us/app/secure-data-kit/id1221587450?ls=1&mt=8">Apple App Store</a> now!</p>
            </div>
            <div className="col-sm-6">
            <div className="bottom-space">

            <p>You just created a survey that was instantly deployed to iPhone, Android, and the Web. See instructions to the left for more details.</p></div>
            <h4>Why SDK for surveys?</h4>
            <ul>
              <li>Works anywhere in the world ( 54 countries and counting regardless of internet access! )</li>
              <li>Supports longitudinal studies</li>
              <li>Export the data for analysis</li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        </div>
        </div>
    }
    if(this.state.browserSupported){
      return (

          <div className="row">
            {publishingStatus}
            <div className="col-md-4 border-right">
              <div className="h-100">
                <FieldList
                  projectUrl={this.state.projectUrl}
                  currentlyBuilding={this.state.currentlyBuilding}
                  startBuilding={this.startBuilding}
                  updateTitle={this.updateTitle}
                  fields={this.state.fields}
                  controlField={this.controlField}
                />
              </div>
            </div>
            <div className={this.state.middleColumnClasses}>
              <div className="h-100">
                <FieldSettings
                  currentlyBuilding={this.state.currentlyBuilding}
                  startBuilding={this.startBuilding}
                  addField={this.addField}
                  controllingField={this.state.controllingField}
                  currentField={this.state.currentField}
                  optionLists={this.state.optionLists}
                  startOptionList={this.startOptionList}
                  addOption={this.addOption}
                  controlOption={this.controlOption}
                  optionEditMode={this.state.optionEditMode}
                  fieldEditMode={this.state.fieldEditMode}
                  selectOptionsList={this.selectOptionsList}
                  currentOptionList={this.state.currentOptionList}
                  currentOption={this.state.currentOption}
                />
              </div>
            </div>
            <div className="col-md-4">
              <FormPreview
                publishForm={this.publishForm}
                currentlyBuilding={this.state.currentlyBuilding}
                formTitle={this.state.formTitle}
                fields={this.state.fields}
                optionLists={this.state.optionLists}
                getLocation={this.getLocation}
                formIsBeingPublished={this.state.formIsBeingPublished}
              />
            </div>
          </div>

      );
    }
    else{
      return (
        <div className="container-fluid form-builder">
          <div className="row">
            <div className="mask active" role="dialog">
            <div className="active builder-modal col-sm-10 bg-light shadow-lg rounded p-4" role="alert">
             <div className="modal-dialog">
               <div>
               <p><span>We're sorry, but it looks like the web browser that you are using does not support the sweet features used by the form builder. Please try upgrading to a different browser. We suggest Google Chrome.</span></p>
               </div>
             </div>
            </div>
            </div>
          </div>
        </div>

      );
    }
  }
}

export default FormBuilder
