import React from "react"
import PropTypes from "prop-types"
class FormPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      formTitle: props.formTitle,
      fields: props.fields,
      optionLists: props.optionLists,
      publishForm: props.publishForm,
      getLocation: props.getLocation,
      gettingLocation: props.gettingLocation,
      formIsBeingPublished: props.formIsBeingPublished
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentlyBuilding: nextProps.currentlyBuilding,
      formTitle: nextProps.formTitle,
      fields: nextProps.fields,
      optionsList: nextProps.optionsList,
      gettingLocation: nextProps.gettingLocation,
      formIsBeingPublished: nextProps.formIsBeingPublished
    });
  }

  getOptionsForSelectOne(field){
    if(this.state.optionLists[field.optionList]){
      let optionsForSelect = [<option key={"empty-option"} value="">-select-</option>];
      let optionList = this.state.optionLists[field.optionList];
      let optionKey;
      optionList.map((option, i) => {
        optionKey = (i + 1).toString();
        optionsForSelect.push(<option key={optionKey.concat("-option")} value={option.name}>{option.label}</option>)
      })
      return optionsForSelect
    }
    else {
      return null
    }
  }

  getOptionsForSelectMultiple(field){
    if(this.state.optionLists[field.optionList]){
      let checkBoxOptions = [];
      let options = this.state.optionLists[field.optionList];
      let multiOptionKey;
      options.forEach(function(option, i) {
        multiOptionKey = (i + 1).toString();
        checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input className="form-checkbox" key={multiOptionKey.concat("-option")} type="checkbox" name={name} value={option.name} />{option.label}</label></div>)
      });
      return checkBoxOptions
    }
    else {
      return null
    }

  }

  getLocation(event) {
    this.setState({
      gettingLocation: true
    })
    this.state.getLocation(event);
  }

  publishForm(event) {
    event.preventDefault();
    this.state.publishForm();
  }

  renderFormPreview(){
    let list = [];
    let key;
    this.state.fields.map((field, i) =>
      {
        key = (i + 1).toString()
        let name = field.name
        let type = field.type
        let label = field.label
        switch(type) {
          case "string":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>
                  {label}</h4>
                </label>
                <input className="form-control" name={name} type="text" placeholder="This is great field for short answers."/>
              </div>
            )
          break;
          case "text":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <textarea className="form-control" name={name} placeholder="This is a great field to hold lots of text. Like the description of an experience, or maybe extra comments. Seriously, a lot of text can fit in this box."></textarea>
              </div>
            );
          break;
          case "int":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" name={name} type="number" placeholder="7"/>
              </div>
            );
          break
          case "decimal":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" name={name} type="number" step="0.01" placeholder="7.5"/>
              </div>
            );
          break;
          case "dateTime":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" type={"datetime-local"} name={name}/>
              </div>
            );
          break;
          case "date":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" type={"date"} name={name}/>
              </div>
            );
          break;
          case "time":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" name={name} type="time"/>
              </div>
            );
          break;
          case "note":
            list.push(
              <div key={key} className="form-group">
                <input className="form-control" name={name} type="text" readOnly/>
                <div key={key.concat("-note")} className="">
                  <h3>{label}</h3>
                </div>
              </div>
            );
          break;
          case "geopoint":
            let spinner = <div className="form-btn"><div className="spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div></div>
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <button type="button" className="yo btn btn-default btn-location" onClick={(event) => this.getLocation(event)}>{(this.state.gettingLocation) ? spinner : "Record Location"}</button>
                <input className="form-control" name={name} type="text" readOnly/>
              </div>
            );
          break;
          case "select":
            let checkBoxOptions = this.getOptionsForSelectMultiple(field);
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                {checkBoxOptions}
              </div>
            );
          break;
          case "select1":
            let optionsForSelect1 = this.getOptionsForSelectOne(field);
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <select className="form-control" name={name} multiple={false} onChange={this.handleChange}>
                  {optionsForSelect1}
                </select>
              </div>
            );
          break;
          case "barcode":
            list.push(
              <div key={key} className="form-group">
                <label>
                <h4>{label}</h4>
                </label>
                <input className="form-control" name={name} type="text" placeholder="Enter barcode"/>
                <small>
                  <label>
                    {`${label} confirmation`}
                  </label>
                </small>
                <input className="form-control" name={`${name}_confirmation`} type="text" placeholder="Confirm barcode"/>
              </div>
            )
          break;
        }
      }
    )
    return (
      <div>
        <h1 className="text-center">{this.state.formTitle}</h1>
        <form onSubmit={event => this.publishForm(event)}>
          {list}
          <div className="form-submit">
              <button disabled={this.state.formIsBeingPublished} key="submit-button" type="submit" className="yo btn btn-secondary btn-block pull-left">Publish Form <i className="fas fa-upload"></i></button>
             <small className="text-muted">
                Once you have published your form you will be able to collect data. We'll provide a public web address for you to share.
              </small>
            </div>
        </form>
      </div>
    )
  }

  render () {
    if (!this.state.currentlyBuilding){
      return <div />
    }
    else if (this.state.currentlyBuilding && this.state.fields.length === 0){
      return (
        <div>
          <h1 className="text-center">{this.state.formTitle}</h1>
          <p>Once you start adding fields, a preview of your form will appear here</p>
        </div>
      )
    }
    else if (this.state.currentlyBuilding && this.state.fields.length > 0){
      return this.renderFormPreview();
    }
  }
}

FormPreview.propTypes = {
  title: PropTypes.string
};
export default FormPreview
