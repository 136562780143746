import React from "react"
import PropTypes from "prop-types"
import OptionListSelect from "./OptionListSelect"
import OptionInputs from "./OptionInputs"
import OptionList from "./OptionList"

class OptionForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      getListNameOptions: props.getListNameOptions,
      startOptionList: props.startOptionList,
      getOptionsList: props.getOptionsList,
      currentOption: props.currentOption,
      addOption: props.addOption,
      currentOptionList: props.currentOptionList,
      showOptionControls: false,
      updateOptionValue: props.updateOptionValue,
      startOptionList: props.startOptionList,
      newOptionListName: '',
      updateOptionValue: props.updateOptionValue,
      optionLists: props.optionLists,
      controlOption: props.controlOption,
      selectOptionsList: props.selectOptionsList,
      editingName: false
    }
    this.controlOption = this.controlOption.bind(this);
    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.updateNewOptionListName = this.updateNewOptionListName.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.updateOptionValue = this.updateOptionValue.bind(this);
    this.addOption = this.addOption.bind(this);
    this.editName = this.editName.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentOption: nextProps.currentOption,
      currentOptionList: nextProps.currentOptionList,
      optionLists: nextProps.optionLists
    })
  }

  updateNewOptionListName(value){
    this.setState({
      newOptionListName: value
    })
  }

  updateOptionValue(event){
    let currentOption = this.state.currentOption;
    if(event.target.name === "label") {currentOption["label"] = event.target.value;}
    if (this.state.editingName){
      if(event.target.name === "name") {currentOption["name"] = event.target.value;}
    }
    else {
      currentOption["name"] = this.generateName();
    }
    this.setState({
      currentOption: currentOption
    })
  }

  generateName(){
    let generatedName = this.state.currentOption['label'].trim()
    .replace(/(^[A-Z])/, ([leadingUppercase]) => leadingUppercase.toLowerCase())
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/([A-Z])/g, ([innerUppercase]) => `${innerUppercase.toLowerCase()}`)
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    .replace(/(^[0-9])/, ([leadingNumber]) => `_${leadingNumber}`)
    return generatedName
  }

  editName(){
    let currentOption = this.state.currentOption;
    this.setState({
      editingName: true,
      currentOption: currentOption
    })
  }

  addOption(event){
    event.preventDefault();
    let listName = this.state.currentOptionList;
    let optionLabel = this.state.currentOption["label"];
    let optionName = this.state.currentOption["name"];
    if(this.optionsValid(optionName, optionLabel)){
      this.state.addOption(listName, optionName, optionLabel);
      this.setState({
        editingName: false
      })
    }
    else{
      alert("Option names, and Option labels are both required to add an option." )
    }
  }

  optionsValid(optionName, optionLabel){
    if(optionName === ''){
      return false
    }
    else if (optionLabel === ''){
      return false
    }
    else{
      return true
    }
  }

  controlOption(option, control){
    this.state.controlOption(option, control);
  }

  selectOptionsList(value){
    this.state.selectOptionsList(value);
  }

  startOptionList(newOptionListName){
    this.state.startOptionList(newOptionListName);
  }

  render () {
    return (
      <div className="options-form">
        <label>Select a list to use, or create a new list of options.</label>
        <OptionListSelect
          optionLists={this.state.optionLists}
          currentOptionList={this.state.currentOptionList}
          selectOptionsList={this.selectOptionsList}
          updateNewOptionListName={this.updateNewOptionListName}
          newOptionListName={this.state.newOptionListName}
          startOptionList={this.startOptionList}
        />
        <OptionList
          optionLists={this.state.optionLists}
          currentOptionList={this.state.currentOptionList}
          controlOption={this.controlOption}
        />
        <OptionInputs
          currentOption={this.state.currentOption}
          updateOptionValue={this.updateOptionValue}
          optionEditMode={this.props.optionEditMode}
          addOption={this.addOption}
          currentOptionList={this.state.currentOptionList}
          editName={this.editName}
          editingName={this.state.editingName}
        />
      </div>
    );
  }
}

export default OptionForm
