import React from "react"
import PropTypes from "prop-types"
import OptionListItem from "./OptionListItem"

class OptionList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentOptionList: props.currentOptionList,
      optionLists: props.optionLists,
      controlOption: props.controlOption
    }
    this.controlOption = this.controlOption.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      currentOptionList: nextProps.currentOptionList,
      optionLists: nextProps.optionLists
    })
  }

  controlOption(option, control){
    this.state.controlOption(option, control);
  }

  renderOptionList(){
    if(this.state.currentOptionList === "new-option-list" || this.state.currentOptionList === ""){
      return []
    }
    else{
      let options = this.state.optionLists[this.state.currentOptionList];
      let optionList;
      if(options){
        optionList = options.map((option, i) =>
            <OptionListItem
              option={option}
              key={i}
              controlOption={this.controlOption}
            />
        );
      }
      else{
        optionList = [];
      }
      return optionList
    }
  }

  render () {
    return this.renderOptionList();
  }
}

export default OptionList
