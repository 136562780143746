import React from "react"
import PropTypes from "prop-types"
class FieldListItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      field: props.field,
      showControls: false,
      fieldTypeDisplay: {
        string: "String",
        text: "Text",
        int: "Integer",
        decimal: "Decimal",
        dateTime: "Date Time",
        date: "Date",
        time: "Time",
        note: "Note",
        geopoint: "Geopoint",
        select1: "Select One",
        select: "Select Multiple",
        barcode: "Barcode"
      },
      controlField: props.controlField
    }
    this.showControls = this.showControls.bind(this);
    this.hideControls = this.hideControls.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      field: nextProps.field
    })
  }

  showControls() {
    this.setState({
      showControls: true
    })
  }

  hideControls() {
    this.setState({
      showControls: false
    })
  }

  controlField(event){
    event.preventDefault();
    let control = event.target.name
    this.state.controlField(this.state.field, control);
  }

  format(label) {
    let maxLength = 6;
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    } else {
      return label;
    }
  };

  render () {
    return (
      <li className={"field-item " + this.state.showControls} onMouseEnter={this.showControls} onMouseLeave={this.hideControls}>
        <p className="item">{this.format(this.state.field.label)} <span className="item-type">| {this.state.fieldTypeDisplay[this.state.field.type]}</span></p>
        {
          this.state.showControls &&
          <div className="controls pull-right">
            <a href="#" className="delete  btn btn-link" name="delete-field" onClick={(event) => this.controlField(event)}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/trash-alt-solid.svg" alt="Trash" className="icon"/> Delete</a>
            <a href="#" className="edit  btn btn-link" name="edit-field" onClick={(event) => this.controlField(event)}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/pencil-alt-regular.svg" alt="Pencil" className="icon"/> Edit</a>
          </div>
        }
      </li>
    );
  }
}

export default FieldListItem
