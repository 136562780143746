import React from "react"
import PropTypes from "prop-types"
import FieldListItem from "./FieldListItem"
class FieldList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      projectUrl: props.projectUrl,
      startBuilding: props.startBuilding,
      updateTitle: props.updateTitle,
      fields: props.fields,
      showControls: false
    }
    this.startBuilding = this.startBuilding.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.controlField = this.controlField.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentlyBuilding: nextProps.currentlyBuilding,
      fields: nextProps.fields
    })
  }

  startBuilding(event){
    event.preventDefault();
    this.state.startBuilding();
  }

  updateTitle(event) {
    this.state.updateTitle(event.target.value);
  }

  controlField(field, control){
    this.props.controlField(field, control);
  }

  render () {
    if (!this.state.currentlyBuilding){
      return (
        <div>
          <h4>Form Settings</h4>
          <h2>Form Title</h2>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title" onChange={this.updateTitle}/>
          <small>What should we call your form?</small>
          <h2>Form Fields</h2>
          <div className="text-center"><small>No Fields Yet</small></div>
          <a className="btn btn-primary btn-block first-field" id="start-build" href="" onClick={(event) => this.startBuilding(event)}>Add a Field</a>
        </div>
      )
    }
    else if(this.state.currentlyBuilding && this.state.fields.length === 0){
      return (
        <div>
          <h4>Form Settings</h4>
          <h2>Form Title</h2>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title" onChange={this.updateTitle}/>
          <small>What should we call your form?</small>
          <h2>Fields</h2>
          <div className="text-center"><small>No Fields Yet</small></div>
        </div>
      )
    }
    else if (this.state.currentlyBuilding && this.state.fields.length > 0){
      let list = this.state.fields.map((field, i) =>
        <FieldListItem
          field={field}
          controlField={this.controlField}
          key={i}
        />
      )
      return (
        <div>
          <h2>Form Title</h2>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title"/>
          <small>What should we call your form?</small>
          <h2>Fields</h2>
          <ul className="field-list">
            {list}
          </ul>
        </div>
      )
    }
  }
}

export default FieldList
