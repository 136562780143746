import React from "react"
import PropTypes from "prop-types"
import OptionForm from "./OptionForm"

class FieldSettings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      field: props.currentField,
      addField: props.addField,
      optionLists: props.optionLists,
      startOptionList: props.startOptionList,
      currentOptionList: props.currentOptionList,
      addOption: props.addOption,
      controlOption: props.controlOption,
      currentOption: props.currentOption,
      selectOptionsList: props.selectOptionsList,
      editingName: false,
      nameHasbeenEdited: false
    }
    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.addOption = this.addOption.bind(this);
    this.controlOption = this.controlOption.bind(this);
    this.updateRequired = this.updateRequired.bind(this);
    this.editName = this.editName.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentlyBuilding: nextProps.currentlyBuilding,
      field: nextProps.currentField,
      optionLists: nextProps.optionLists,
      currentOptionList: nextProps.currentField["optionList"],
      currentOptionList: nextProps.currentOptionList,
      currentOption: nextProps.currentOption
    })
  }

  updateValue(event){
    event.preventDefault();
    let field = this.state.field;
    switch(event.target.name){
      case 'label':
        field["label"] = event.target.value;
        if(!this.state.nameHasbeenEdited){
          field["name"] = this.generateName()
        }
        break;
      case 'name':
        if(this.state.editingName) {
          field["name"] = event.target.value;
        }
        break;
      case 'type':
        field["type"] = event.target.value;
        break;
    }
    this.setState({
      field: field
    })
  }

  updateRequired(){
    let field = this.state.field;
    field["required"] = !field["required"]
    this.setState({
      field: field
    })
  }

  updateOptionValue(event){
    let currentOption = this.state.currentOption;
    currentOption[event.target.name] = event.target.value;
    this.setState({
      currentOption: currentOption
    })
  }

  generateName(){
    let generatedName = this.state.field['label'].trim()
    .replace(/(^[A-Z])/, ([leadingUppercase]) => leadingUppercase.toLowerCase())
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/([A-Z])/g, ([innerUppercase]) => `${innerUppercase.toLowerCase()}`)
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    .replace(/(^[0-9])/, ([leadingNumber]) => `_${leadingNumber}`)
    return generatedName.substring(0, 64)
  }

  editName(){
    this.setState({
      editingName: !this.state.editingName,
      nameHasbeenEdited: true
    })
  }

  addField(event){
    let field = this.state.field;
    if (field['name'] === '') {field['name'] = this.generateName()};
    this.state.addField(field);
  }

  addOption(listName, optionName, optionLabel){
    this.state.addOption(listName, optionName, optionLabel);
  }

  controlOption(option, control){
    this.state.controlOption(option, control);
  }

  selectOptionsList(listName){
    this.state.selectOptionsList(listName);
  }

  startOptionList(newOptionListName){
    this.state.startOptionList(newOptionListName);
  }

  render () {
    if (!this.state.currentlyBuilding){
      return (
        <div>
            <h4>Form Designer</h4>
            <p>Don't you love a blank canvas?  Secure Data Kit's Form Designer allows you to quickly & easily design your dataset from scratch.</p>
            <p>Here's how to get started:</p>
            <ol>
              <li className="assign-label">Assign a title to your form</li>
              <li className="customize-label">Add customizable fields</li>
              <li className="start-label">Start Transforming Lives in minutes!</li>
            </ol>
            <h2 className="field-types-header">Field Types...</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="type type-text">
                  <div className="copy">
                    <h3>Text</h3>
                    <small>String & Text</small>
                    <p><strong>Strings</strong> are useful for short pieces of text (such as names).  The <strong>text</strong> field expands as you type + line breaks can be included, making the field type ideal for longer text (such as sentences).</p>
                  </div>
                </div>
                <div className="type type-date">
                  <div className="copy">
                    <h3>Date</h3>
                    <small>Date, Time, & DateTime</small>
                    <p>Capturing <strong>date</strong>, <strong>time</strong>, or <strong>both</strong> can be useful for recording when data was collected or storing any other dates relevant to your form.</p>
                  </div>
                </div>
                <div className="type type-choice">
                  <div className="copy">
                    <h3>Choice</h3>
                    <small>Select One & Select Multiple</small>
                    <p>You can easily use <strong>single choice</strong> or <strong>multiple choice</strong> questions to help control the data being gathered.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="type type-number">
                  <div className="copy">
                    <h3>Number</h3>
                    <small>Integer & Decimal</small>
                    <p>An <strong>integer</strong> is a whole number that can be positive, negative, or zero. A <strong>decimal</strong> is a floating point number (such as 1.05).</p>
                  </div>
                </div>
                <div className="type type-location">
                  <div className="copy">
                    <h3>Location</h3>
                    <small>Geopoint</small>
                    <p>GPS coordinates collected with the <strong>geopoint</strong> location field type are automatically plotted on a map in your dataset manager.</p>
                  </div>
                </div>
                <div className="type type-help">
                  <div className="copy">
                    <h3>Description</h3>
                    <small>Note</small>
                    <p>Add helpful descriptions or <strong>notes</strong> to your form — great for introducing a group of related questions or adding instructions to help gather accurate data.</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      )
    }
    else if (this.state.currentlyBuilding){
      let optionsForm;
      if (this.state.field["type"].includes("select")){
        optionsForm =
          <OptionForm
            getListNameOptions={this.getListNameOptions}
            startOptionList={this.startOptionList}
            currentOption={this.state.currentOption}
            addOption={this.addOption}
            currentOptionList={this.state.currentOptionList}
            selectOptionsList={this.selectOptionsList}
            optionEditMode={this.props.optionEditMode}
            updateOptionValue={this.updateOptionValue}
            optionLists={this.state.optionLists}
            controlOption={this.controlOption}
          />
      }
      return (
        <div>
            <div className="h-100">
              <h2>Field Label</h2>
              <input type="text" className="form-control" name="label" placeholder="Field Label" value={this.state.field["label"]} onChange={(event) => this.updateValue(event)}/>
              <small>Type the question as it will display in your form</small>
              <h2>Field Name</h2>
              <input type="text" className="form-control" name="name" placeholder="Field Name" onFocus={this.editName} onBlur={this.editName} value={this.state.nameHasbeenEdited ? this.state.field["name"] : this.generateName()} onChange={(event) => this.updateValue(event)}/>
              <small>The unique variable name for this data point</small>
              <h2>Field Type</h2>
              <select className="form-control" name="type" value={this.state.field["type"]} onChange={(event) => this.updateValue(event)}>
                <option value="">-choose type-</option>
                <option value="string">String</option>
                <option value="text">Text</option>
                <option value="int">Integer</option>
                <option value="decimal">Decimal</option>
                <option value="dateTime">Date Time</option>
                <option value="date">Date</option>
                <option value="time">Time</option>
                <option value="note">Note</option>
                <option value="geopoint">Geopoint</option>
                <option value="select1">Select One</option>
                <option value="select">Select Multiple</option>
              </select>
              <small>Select your question type</small>
              {optionsForm }
              {
                this.state.field["type"] === "note"
                ? null
                : <div><h2>Required</h2><label><input className="form-checkbox" type="checkbox" name="required" checked={this.state.field["required"]} onChange={this.updateRequired} /> Check this box to make this question require a response.</label></div>
              }
              <div className=""><button id="save-field-btn" className="btn btn-block btn-primary save" onClick={(event) => this.addField(event)}>{this.props.fieldEditMode ? "Save Edit":"Add Field"}</button></div>
            </div>
        </div>
      )
    }
  }
}

export default FieldSettings
