import React from "react"
import PropTypes from "prop-types"
class BuilderPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      projectUrl: props.projectUrl,
      previewFields: props.previewFields,
      updateTitle: props.updateTitle,
      updateField: props.updateField,
      editingField: props.editingField,
      fieldToEdit: props.fieldToEdit,
      newValues: {
        type: props.newValues["type"],
        name: props.newValues["name"],
        labelEnglish: props.newValues["labelEnglish"],
        options: props.newValues["options"]
      },
      editValues: {
        type: props.fieldToEdit["type"],
        name: props.fieldToEdit["name"],
        labelEnglish: props.fieldToEdit["labelEnglish"],
        options: props.fieldToEdit["options"]
      },
      showOptionsForm: props.showOptionsForm,
      editOptionListName: props.editOptionListName,
      newOptionListName: props.newOptionListName,
      updateOptionListName: props.updateOptionListName,
      updateOption: props.updateOption,
      updatingOption: props.updatingOption,
      newOption: props.newOption,
      newOptions: props.newOptions,
      addOption: props.addOption,
      editOption: props.editOption,
      deleteOption: props.deleteOption,
      showOptionControls: false,
      editingOption: props.editingOption,
      optionToEdit: props.optionToEdit,
      startOptionList: props.startOptionList,
      editOptions: props.editOptions,
      previewOptions: props.previewOptions
    };
    this.updateTitle = this.updateTitle.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateOptionListName = this.updateOptionListName.bind(this);
    this.updateOption = this.updateOption.bind(this);
    this.addOption = this.addOption.bind(this);
    this.editOption = this.editOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showOptionControls = this.showOptionControls.bind(this);
    this.hideOptionControls = this.hideOptionControls.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editingField){
      this.setState({
        newValues: {
          type: "",
          name: "",
          labelEnglish: ""
        },
        editValues: {
          type: nextProps.fieldToEdit["type"],
          name: nextProps.fieldToEdit["name"],
          labelEnglish: nextProps.fieldToEdit["labelEnglish"],
          options: nextProps.fieldToEdit["options"]
        },
        fieldToEdit: nextProps.fieldToEdit,
        editingField: nextProps.editingField,
        showOptionsForm: nextProps.showOptionsForm,
        newOptionListName: nextProps.newOptionListName,
        newOption: nextProps.newOption,
        editingOption: nextProps.editingOption,
        optionToEdit: nextProps.optionToEdit,
        editOptions: nextProps.editOptions,
        previewOptions: nextProps.previewOptions,
        updatingOption: nextProps.updatingOption
      })
    }else{
      this.setState({
        newValues: {
          type: nextProps.newValues["type"],
          name: nextProps.newValues["name"],
          labelEnglish: nextProps.newValues["labelEnglish"],
          options: nextProps.newValues["options"]
        },
        fieldToEdit: {
          type: "",
          name: "",
          labelEnglish: "",
          options: []
        },
        editingField: nextProps.editingField,
        showOptionsForm: nextProps.showOptionsForm,
        newOptionListName: nextProps.newOptionListName,
        newOptions: nextProps.newOptions,
        newOption: nextProps.newOption,
        editingOption: nextProps.editingOption,
        optionToEdit: nextProps.optionToEdit,
        previewOptions: nextProps.previewOptions,
        updatingOption: nextProps.updatingOption,
      })
    }
  }
  hideOptionControls() {
    this.setState({
      showOptionControls: false
    })
  }

  showOptionControls() {
    this.setState({
      showOptionControls: true
    })
  }
  updateTitle(event) {
    this.state.updateTitle(event.target.value);
  }
  updateField(event) {
    if(this.state.editingField){
      let editValues = this.state.editValues;
      editValues[event.target.name] = event.target.value;
      this.setState({editValues: editValues})
    }else{
      let newValues = this.state.newValues;
      newValues[event.target.name] = event.target.value;
      this.setState({newValues: newValues})
    }
    this.state.updateField(event);
  }
  updateOptionListName(event){
    this.state.updateOptionListName(event);
  }
  updateOption(event){
    this.state.updateOption(event);
  }
  addOption(event){
    this.state.addOption(event);
  }
  editOption(field) {
    this.state.editOption(field);
  }

  deleteOption(field) {
    this.state.deleteOption(field);
  }
  startOptionList(event){
    this.state.startOptionList(event);
  }
  validField(fieldAttributes){
    for(let i = 0; i < fieldAttributes.length; i++){
      switch(fieldAttributes[i].value){
        case "":
          let whitelistTypes = ["submit", "button"]
          if(whitelistTypes.indexOf(fieldAttributes[i].type) > -1 || fieldAttributes[i].name.includes("option_")){
            return true
          }else{
            return false
          }
        break;
      }
    }
    return true
  }
  handleSubmit(event) {
    event.preventDefault();
    let fieldAttributes = document.getElementById("field-attribute-form").elements;
    if (this.validField(fieldAttributes)){
      this.state.previewFields();
    }else{
      alert("Values for Name, Type, and Label are required to create a new field.")
    }
  }
  render () {
    let options = this.state.previewOptions;
    let optionList = options.map((option, i) =>
    <li key={i} onMouseLeave={this.hideOptionControls} onMouseEnter={this.showOptionControls}>
      {option["option_labelEnglish"]}
      {this.state.showOptionControls &&   <span onClick={(event) => this.editOption(option, event)} className="form-builder-option-controls">Edit</span>}
      {this.state.showOptionControls &&   <span onClick={(event) => this.deleteOption(option, event)} className="form-builder-option-controls">Delete</span>}
    </li>
    );
    let optionsForm;
    if (this.state.showOptionsForm){
      optionsForm =
      <div className="options-form">
        <label>Name of Options List</label>
        <input value={this.state.newOptionListName} className="form-control" name="option_list_name" type="text" placeholder="option_list_name" onChange={this.updateOptionListName} onBlur={this.startOptionList}/>
        <ul>{optionList}</ul>
        <label>Option Name</label>
        <input value={this.state.updatingOption["option_name"]} className="form-control" name="option_name" type="text" placeholder="option_name" onChange={this.updateOption} />
        <label>Option Label</label>
        <input value={this.state.updatingOption["option_labelEnglish"]} className="form-control" name="option_labelEnglish" type="text" placeholder="Option Label" onChange={this.updateOption} />
        <button key="options-form-button" type="button" className="yo btn btn-default" onClick={(event) => this.addOption(event)}>{(this.state.editingOption) ? "Save edit" : "Add Option"}</button>
      </div>
    }
    return (
      <div className="helper-area">
        <h1>Form Title</h1>
        <input id="builder-title" className="form-control" name="title" type="text" placeholder="New title" onChange={this.updateTitle} />
        <br/>
        <h1>{(this.state.editingField) ? "Edit" : "New"} Field</h1>
        <form id="field-attribute-form" onSubmit={event => this.handleSubmit(event)}>
          <label>Type</label>
          <select value={(this.state.editingField) ? this.state.editValues["type"] : this.state.newValues["type"]} className="form-control" name="type" onChange={this.updateField}>
            <option value="">-choose type-</option>
            <option value="string">String</option>
            <option value="text">Text</option>
            <option value="int">Integer</option>
            <option value="decimal">Decimal</option>
            <option value="dateTime">Date Time</option>
            <option value="date">Date</option>
            <option value="time">Time</option>
            <option value="note">Note</option>
            <option value="geopoint">Geopoint</option>
            <option value="select1">Select One</option>
            <option value="select">Select Multiple</option>
          </select>
          <label>Name</label>
          <input value={(this.state.editingField) ? this.state.editValues["name"] : this.state.newValues["name"]} className="form-control" name="name" type="text" placeholder="field_name" onChange={this.updateField} />
          <label>Label</label>
          <input value={(this.state.editingField) ? this.state.editValues["labelEnglish"] : this.state.newValues["labelEnglish"]} className="form-control" name="labelEnglish" type="text" placeholder="Field Label" onChange={this.updateField} />
          {optionsForm}
            <button key="submit-button" type="submit" className="yo btn btn-default ">{(this.state.editingField) ? "Save edit" : "Add field"}</button>
        </form>
      </div>
    );
  }
}

BuilderPanel.propTypes = {
  title: PropTypes.string
};
export default BuilderPanel
