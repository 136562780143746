import React from "react"
import PropTypes from "prop-types"
class OptionInputs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentOption: props.currentOption,
      showOptionInputs: props.showOptionInputs,
      currentOptionList: props.currentOptionList
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      currentOption: nextProps.currentOption,
      showOptionInputs: nextProps.showOptionInputs,
      currentOptionList: nextProps.currentOptionList
    })
  }

  updateOptionValue(event){
    this.props.updateOptionValue(event);
  }

  addOption(event){
    this.props.addOption(event);
  }

  render () {
    if(this.state.currentOptionList === "new-option-list" || this.state.currentOptionList === ""){
      return <div />;
    }
    else{
      return (
        <div>
          <label>Option Label</label>
          <input className="form-control" id="option-label-input" name="label" type="text" placeholder="Option Label" value={this.state.currentOption["label"]} onChange={(event) => this.updateOptionValue(event)}/>
          <small>The name you see on the list</small>
          <div className="option-help"></div>
          <label>Option Name</label>
          <input type="text" className="form-control" id="option-name-input" name="name" placeholder="option_name" onFocus={this.props.editName} value={this.state.currentOption["name"]} onChange={(event) => this.updateOptionValue(event)}/>
          <small>The value that gets saved</small>
          <div className="option-help"></div>
          <button key="options-form-button" id="add-option-btn" type="button" className="yo btn btn-default" onClick={(event) => this.addOption(event)}>{this.props.optionEditMode ? "Save Edit":"Add Option"}</button>
        </div>
      );
    }
  }
}

export default OptionInputs
